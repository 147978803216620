import styles from './TagHeroLeft.module.css'
import PostCardUI from 'shared-components/posts/PostCardUI'
import PostedByContainer from 'shared-components/posts/PostedByContainer'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import AppImage from 'shared-components/ui/AppImage'
import Linked from 'shared-components/service/Linked'

interface TagHeroLeftProps extends BasicStyledComponent {
  post: DSPostShortWithAuthor
}

const TagHeroLeft: React.VFC<TagHeroLeftProps> = ({ className, post }) => (
  <Linked className={clsx(styles.container, className)} {...post.pLink}>
    {post.image ? (
      <div className={styles.imageContainer}>
        <AppImage
          image={post.image}
          layout="responsive"
          className={styles.image}
          sizes={[{ bp: 'lg', val: 464 }, 'calc(100vw - rem(30))']}
        />
        <PostCardUI post={post} spaced />
      </div>
    ) : null}
    <div>
      <div className={styles.title}>{post.title}</div>
      <div className={styles.excerpt}>{post.excerpt}</div>
      <PostedByContainer
        post={post}
        className={styles.postedBy}
        dateClassName={styles.postedByDate}
      />
    </div>
  </Linked>
)

export default TagHeroLeft
