import PostRow from 'shared-components/posts/PostRow'
import SidebarLayout from 'shared-components/ui/SidebarLayout'
import LoadMoreList from 'shared-components/ui/LoadMoreList'
import stylesCommon from './SearchTemplate.TagTemplate.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import TagHero from 'shared-components/posts/TagHero'
import React, { useCallback } from 'react'
import { DSTagPage, DSPostShortWithAuthor } from 'shared-definitions/types'
import styles from './TagTemplate.module.css'
import clsx from 'clsx'

interface TagTemplateProps {
  page: DSTagPage
  pageLoader: (page: number, perPage: number) => Promise<DSPostShortWithAuthor[]>
  sideBar: React.ReactElement
}

const TagTemplate: React.VFC<TagTemplateProps> = ({ page, pageLoader, sideBar }) => {
  const render = useCallback(
    (item: DSPostShortWithAuthor, index: number) => <PostRow key={index} post={item} large />,
    []
  )

  return (
    <div className={styleUtils.wrapper}>
      <div className={stylesCommon.preTitle}>{'Pages tagged:'}</div>
      <h1 className={styleUtils.pageTitle}>{page.title}</h1>
      <TagHero
        posts={page.posts.items.slice(0, 4)}
        className={clsx(stylesCommon.content, styles.hero)}
      />
      <SidebarLayout
        content={
          <LoadMoreList
            autoload
            render={render}
            className={styles.list}
            initial={{ ...page.posts, items: page.posts.items.slice(4) }}
            loader={pageLoader}
          />
        }
        sidebar={sideBar}
      />
    </div>
  )
}

export default TagTemplate
