import styles from './TagHeroRight.module.css'
import PostedByContainer from 'shared-components/posts/PostedByContainer'
import AppImage from 'shared-components/ui/AppImage'
import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'

interface TagHeroRightProps extends BasicStyledComponent {
  post: DSPostShortWithAuthor
}

const TagHeroRight: React.VFC<TagHeroRightProps> = ({ post, className }) => (
  <Linked className={clsx(styles.container, className)} {...post.pLink}>
    {post.image ? (
      <AppImage
        image={post.image}
        layout="raw"
        className={styles.imageContainer}
        sizes={[{ bp: 'lg', val: 386 }, 'calc(100vw - rem(30))']}
      />
    ) : null}
    <div className={styles.content}>
      <PostedByContainer post={post} className={styles.postedBy} />
      <div className={styles.title}>{post.title}</div>
    </div>
  </Linked>
)

export default TagHeroRight
