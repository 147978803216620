import styles from './PlaywireTagged.module.css'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { useCallback, useEffect, useRef } from 'react'
import { logNotice, logWarning } from 'shared-code/log'
import { uniqueIdString } from 'shared-code/util'

interface PlaywireTaggedProps extends BasicStyledComponent {
  type: PlaywireTaggedType
}

const PlaywireTagged: React.VFC<PlaywireTaggedProps> = ({ className, type }) => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const divRef = useRef<HTMLDivElement>()
  const { inst, enabled, adClassList } = PlaywireContext.useContainer()

  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      divRef.current = node
      ref(node)
    },
    [ref]
  )

  useEffect(() => {
    if (!inst || !inView || !enabled) {
      return
    }

    const { addUnits, displayUnits, destroyUnits } = inst

    const initializer = (async () => {
      const { current } = divRef
      if (!current) {
        return
      }

      // to prevent duplicated ads
      if (current.childElementCount > 0) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {}
      }

      current.id = uniqueIdString()
      try {
        logNotice('Adding new units to tyche', type)
        await addUnits([
          {
            type,
            selectorId: current.id,
          },
        ])
        await displayUnits()
      } catch (e) {
        logWarning('Playwire error', e)
      }

      return () => {
        const inner = current.querySelector('.pw-tag')
        if (inner) {
          const { id } = inner
          if (id) {
            logNotice('Destroying tagged units', id)
            void destroyUnits(id)
          }
        }
      }
    })()

    return () => {
      // eslint-disable-next-line promise/prefer-await-to-callbacks
      void initializer.then(cb => cb && cb())
      const { current } = divRef
      if (current) {
        current.textContent = ''
      }
    }
  }, [inst, inView, type, enabled])

  if (!enabled) {
    return null
  }

  return (
    <div
      data-ad-type={type}
      ref={setRefs}
      className={clsx(...adClassList, styles.container, className, {
        [styles.rect]: type === 'med_rect_atf' || type === 'med_rect_btf',
        [styles.leaderboard]: type === 'leaderboard_atf' || type === 'leaderboard_btf',
      })}
    />
  )
}

export default PlaywireTagged
