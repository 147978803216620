import styles from './TagHero.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import TagHeroLeft from 'shared-components/posts/TagHeroLeft'
import TagHeroRight from 'shared-components/posts/TagHeroRight'
import PostRow from 'shared-components/posts/PostRow'

interface TagHeroProps extends BasicStyledComponent {
  posts: readonly DSPostShortWithAuthor[]
}

const TagHero: React.VFC<TagHeroProps> = ({ className, posts }) => {
  const [left, right, ...other] = posts
  return (
    <div className={clsx(styles.container, className)}>
      {left ? <TagHeroLeft className={styles.cardLeft} post={left} /> : null}
      {right ? <TagHeroRight className={styles.cardRight} post={right} /> : null}
      {other.map((item, index) => (
        <PostRow
          post={item}
          key={index}
          className={clsx({
            [styles.rowLeft]: index % 2 === 0,
            [styles.rowRight]: index % 2 === 1,
          })}
        />
      ))}
    </div>
  )
}

export default TagHero
