/* eslint-disable react/display-name */
import { BasicStyledComponent } from 'shared-definitions/types'
import clsx from 'clsx'
import styles from './SidebarLayout.module.css'
import { usePageBodyLayout } from 'shared-components/contexts/PageBodyContext'

interface SidebarLayoutProps extends BasicStyledComponent {
  content: React.ReactNode
  sidebar: React.ReactNode
}

const SidebarLayout: React.VFC<SidebarLayoutProps> = ({ className, content, sidebar }) => (
  <div className={clsx(styles.container, className)} ref={usePageBodyLayout()}>
    <div className={styles.content}>{content}</div>
    <aside className={styles.sidebar}>{sidebar}</aside>
  </div>
)

export default SidebarLayout
